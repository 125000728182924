.clue-box {
  box-shadow: inset 1px 1px 1px 1px #0600ed;
  background-color: #0300af;
  border-top: 5px solid #00003a;
  border-left: 5px solid #00003a;
  border-right: 5px solid #00003a;
  border-bottom: 5px solid #00003a;
  vertical-align: none;
  padding: none;
}

.clue-value {
  color: #f29649;
  text-shadow: 2px 2px 2px #000;
  font-weight: bold;
  font-size: 50px;
  cursor: pointer;

  text-transform: uppercase;
  text-decoration: none;
  transform: scale(1, 2.5);
  -webkit-transform: scale(1, 2.5); /* Safari and Chrome */
  -moz-transform: scale(1, 2.5); /* Firefox */
  -ms-transform: scale(1, 2.5); /* IE 9+ */
  -o-transform: scale(1, 2.5); /* Opera */
}

.clue-view {
  background-color: #00003a;
  color: white;
}